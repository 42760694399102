import React from "react";
import { FaArchway, FaCode, FaHandsHelping, FaTasks } from "react-icons/fa";
import { HiCurrencyDollar } from "react-icons/hi";
import { AiFillApi } from "react-icons/ai";
import ServiceItem from "./ServiceItem";

const serviceData = [
  {
    id: 1,
    icon: <FaHandsHelping />,
    title: "atuacao.consultoria.titulo",
    description: "atuacao.consultoria.descricao"
  },
  {
    id: 3,
    icon: <HiCurrencyDollar />,
    title: "atuacao.openfinance.titulo",
    description: "atuacao.openfinance.descricao",
  },
  {
    id: 2,
    icon: <FaTasks />,
    title: "atuacao.gestao.titulo",
    description: "atuacao.gestao.descricao",
  },
  {
    id: 3,
    icon: <FaArchway />,
    title: "atuacao.arquitetura.titulo",
    description: "atuacao.arquitetura.descricao",
  },
  {
    id: 4,
    icon: <FaCode />,
    title: "atuacao.desenvolvimento.titulo",
    description: "atuacao.desenvolvimento.descricao",
  },
  {
    id: 5,
    icon: <AiFillApi />,
    title: "atuacao.apis.titulo",
    description: "atuacao.apis.descricao",
  },
];

const Service = () => {
  return (
    <section className="pb-10">
      <div className="flex flex-wrap md:px-4">
        {serviceData.map((service, id) => (
          <ServiceItem service={service} key={id} />
        ))}
      </div>
    </section>
  );
};

export default Service;
