import React from "react";
import {
  FaFileAlt,
  FaGithub,
  FaInstagram,
  FaLinkedinIn,
  FaNpm,
  FaSkype,
} from "react-icons/fa";
import profile from "../../../images/profile.jpg";
import br from "../../../images/flag_br.png";
import us from "../../../images/flag_us.png";
import { withTranslation, Trans } from 'react-i18next';

const socials = [
  {
    id: 1,
    icon: <FaLinkedinIn />,
    link: "https://www.linkedin.com/in/danielsitnik/",
  },
  {
    id: 2,
    icon: <FaGithub />,
    link: "https://github.com/danielsitnik/",
  },
  {
    id: 3,
    icon: <FaInstagram />,
    link: "https://www.instagram.com/danielsitnik/",
  },
  {
    id: 4,
    icon: <FaNpm />,
    link: "https://www.npmjs.com/~danielsitnik/"
  },
  {
    id: 5,
    icon: <FaSkype />,
    link: "https://join.skype.com/invite/mEXDI4WOhMDX"
  },
];

const Sidebar = ({ t }) => {
  return (
    <aside className="sticky top-0 bg-white md:mx-8 lg:mx-4 mb-8 p-6 shadow-md rounded-md -mt-40">
      <div className="w-24 overflow-hidden -mx-12 float-right mb-5 -mt-3">
        <a href="/?lng=pt"><img src={br} className="h-6 inline" alt="pt-br" />&nbsp;</a>
        <a href="/?lng=en"><img src={us} className="h-6 inline" alt="en-us" /></a>
      </div>
      <div className="w-32 h-32 rounded-md overflow-hidden mx-auto mb-5 clear-both">
        <img src={profile} alt="Daniel Sitnik" className="w-full rounded-full" />
      </div>
      <div className="text-center">
        <h1 className="text-xl text-gray-800 font-bold mb-1">Daniel Sitnik</h1>
        <p className="text-sm text-gray-400 mb-3">
          Associate Director {t('sidebar.na')}
          <a href="https://www.accenture.com/br-pt/about/accenture-interactive-index" rel="noreferrer" target="_blank" className="text-purple-600 pl-1">
            Accenture Song
          </a>
        </p>
        <a
          href="/resume.pdf"
          rel="noreferrer"
          target="_blank"
          className="inline-block mb-3 rounded bg-purple-600 text-center border-0 py-2 px-6 text-white leading-7 tracking-wide hover:bg-purple-800"
          download="resume_daniel_sitnik.pdf"
        >
          <FaFileAlt className="inline align-middle pb-0.5" /> {t('sidebar.cvDigital')}
        </a>
        <ul className="flex flex-wrap justify-center">
          {socials.map((social, id) => (
            <SocialIcon social={social} key={id} />
          ))}
        </ul>
      </div>
      <div className="text-start pt-4">
        <h3 className="text-md mb-2 uppercase font-medium text-gray-800">
          {t('sidebar.sobreMim')}
        </h3>
        <p className="text-gray-400 text font-light leading-relaxed">
          {t('sidebar.sobreMimTextoP1')}
        </p>
        <p className="text-gray-400 text font-light leading-relaxed">
          <Trans i18nKey="sidebar.sobreMimTextoP2">
            Atualmente lidero a prática de <span className="text-purple-600">Cloud Native Applications </span>
            da Accenture.
          </Trans>
        </p>
      </div>
    </aside>
  );
};

export default withTranslation()(Sidebar);

const SocialIcon = (props) => {
  const { icon, link } = props.social;
  return (
    <li className="m-2">
      <a
        href={link}
        rel="noreferrer"
        target="_blank"
        className="w-8 h-8 bg-purple-100 rounded text-purple-800 flex items-center justify-center hover:text-white hover:bg-purple-600"
      >
        {icon}
      </a>
    </li>
  );
};
