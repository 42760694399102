import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import Skills from "./Skills/Skills";
import { Trans } from 'react-i18next';

const birthDate = new Date(1982, 9, 8);
const today = new Date();
const age = Math.floor((today - birthDate) / (1000 * 60 * 60 * 24 * 365));

const About = () => {
  
  return (
    <section className="py-8">
      <div className="flex flex-wrap md:px-4">
        <div className="w-full">
          <div className="md:mx-4">
            <h3 className="text-2xl text-gray-800 font-bold mb-4"><Trans i18nKey="sobre.titulo">Quem sou eu?</Trans></h3>
            <p className="text-sm text-gray-400 leading-6 mb-3">
              <Trans i18nKey="sobre.textoP1">
                Meu nome é Daniel, tenho {{age}} anos e moro em São Paulo, Brasil.
              </Trans>
            </p>
            <p className="text-sm text-gray-400 leading-6 mb-3">
              <Trans i18nKey="sobre.textoP2">
                Atualmente sou o líder da prática de <span className="text-purple-600">Cloud Native Applications </span>
                da Accenture Interactive. Sou responsável por um grupo de cerca de 70 pessoas, e por nossas ofertas de serviços,
                propostas, contratação, treinamento, engajamento e alocação dos times nos projetos.
              </Trans>
            </p>
            <p className="text-sm text-gray-400 leading-6 mb-3">
              <Trans i18nKey="sobre.textoP3">
                Me formei em Administração de Empresas na Universidade Estadual de Londrina (<span className="text-purple-600">UEL</span>),
                e logo depois em Ciência da Computação na Universidade Federal de São Carlos (<span className="text-purple-600">UFSCar</span>).
                Também tenho um MBA Executivo pelo <span className="text-purple-600">Insper</span>.
              </Trans>
            </p>
            <p className="text-sm text-gray-400 leading-6 mb-3">
              <Trans i18nKey="sobre.textoP4">
                Aprendi a programar sozinho com 14 anos e criei um software chamado&nbsp;
                <a className="text-purple-600" href="https://encoder2002.com" rel="noreferrer" target="_blank">encoder<sup>2002</sup>&nbsp;<FaExternalLinkAlt className="inline pb-0.5" /></a>, que fez bastante sucesso
                no Brasil, sendo citado em jornais e até distribuído em revistas de informática.
              </Trans>
            </p>
            <p className="text-sm text-gray-400 leading-6 mb-3">
              <Trans i18nKey="sobre.textoP5">
                Fiz minha carreira na <span className="text-purple-600">Accenture</span>, onde comecei em 2010 como estagiário.
                Passei por diversos projetos, principalmente em clientes da Indústria Financeira, onde desenvolvi skills de arquitetura,
                consultoria e gestão de equipes de desenvolvimento.
              </Trans>
            </p>
          </div>
          <Skills />
        </div>
      </div>
    </section>
  );
};

export default About;
