import React from "react";
import {
  NavLink,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import About from "../../pages/About/About";
import Blog from "../../pages/Blog/Blog";
import Contact from "../../pages/Contact/Contact";
import Portfolio from "../../pages/Portfolio/Portfolio";
import Service from "../../pages/Service/Service";
import { useTranslation } from 'react-i18next';

const navbarData = [
  {
    id: 1,
    title: "menu.sobre",
    to: "/about",
  },
  {
    id: 2,
    title: "menu.areas",
    to: "/services",
  },
  {
    id: 3,
    title: "menu.carreira",
    to: "/works",
  },
  {
    id: 4,
    title: "menu.interesses",
    to: "/interests",
  },
  {
    id: 5,
    title: "menu.contato",
    to: "/contact",
  },
];

const Navbar = () => {

  const history = useHistory();
  React.useEffect(() => {
    history.listen(trackPageView);
  }, [history]);

  function trackPageView() {
    if ("production" === "%NODE_ENV%") {
      window.gtag('event', 'page_view', {
        'page_title': getPageName(window.location.pathname),
        'page_location': window.location.href,
        'page_path': window.location.pathname
      });
    }

    function getPageName(pathname) {
      let page = pathname.substr(1);

      if (page === '') {
        page = 'about';
      }

      // capitalize
      page = page[0].toUpperCase() + page.substr(1);

      // adjust home/about
      if (page === 'About') {
        page = 'Home/About';
      }

      return page;
    }
  }

  return (
  <div>
    <nav className="md:mx-8 mb-3 px-6 py-2 z-10 sticky top-0 bg-white shadow rounded">
      <ul className="flex flex-wrap">
        {navbarData.map((el, id) => (
          <LinkItem el={el} key={id} />
        ))}
      </ul>
    </nav>

    <Switch>
      <Route exact path="/">
        <About />
      </Route>
      <Route path="/services">
        <Service />
      </Route>
      <Route path="/works">
        <Portfolio />
      </Route>
      <Route path="/interests">
        <Blog />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
      <Route path="/about">
        <About />
      </Route>
    </Switch>
  </div>
  );
};

export default Navbar;

const LinkItem = (props) => {
  const { title, to } = props.el;
  const { t } = useTranslation();
  return (
    <li className="m-3 lg:mx-5">
      <NavLink
        to={to}
        activeClassName="text-purple-600"
        className="text-gray-800 text-medium hover:text-purple-600"
      >
        {t(title)}
      </NavLink>
    </li>
  );
};
