import React from "react";
import music from "../../../images/blog/music.jpg";
import videogame from "../../../images/blog/videogame.jpg";
import photo from "../../../images/blog/photography.jpg";
import bike from "../../../images/blog/bike.jpg";
import BlogItem from "./BlogItem";
import { FaInstagram, FaSpotify, FaSteam, FaStrava } from "react-icons/fa";

const blogData = [
  {
    id: 1,
    image: music,
    icon: <FaSpotify className="inline align-middle pb-0.5" />,
    title: "interesses.musica.titulo",
    link: "https://open.spotify.com/user/12150368361?si=1ec00af7de8741de",
    description: "interesses.musica.descricao",
  },
  {
    id: 2,
    image: photo,
    icon: <FaInstagram className="inline align-middle pb-0.5" />,
    title: "interesses.fotografia.titulo",
    link: "https://www.instagram.com/danielsitnik/",
    description: "interesses.fotografia.descricao",
  },
  {
    id: 3,
    image: videogame,
    icon: <FaSteam className="inline align-middle pb-0.5" />,
    title: "interesses.videogames.titulo",
    link: "https://steamcommunity.com/id/sitnik/",
    description: "interesses.videogames.descricao",
  },
  {
    id: 4,
    image: bike,
    icon: <FaStrava className="inline align-middle pb-0.5" />,
    title: "interesses.ciclismo.titulo",
    link: "https://www.strava.com/athletes/4175922",
    description: "interesses.ciclismo.descricao",
  },
];

const Blog = () => {
  return (
    <section className="pb-10">
      <div className="flex flex-wrap md:px-4">
        {blogData.map((blog, id) => (
          <BlogItem blog={blog} key={id} />
        ))}
      </div>
    </section>
  );
};

export default Blog;
