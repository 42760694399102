import React from "react";
import accenture from "../../../images/portfolio/accenture.jpg";
import borabike from "../../../images/portfolio/bora-bike.jpg"
import ufscar from "../../../images/portfolio/ufscar.jpg";
import PortfolioItem from "./PortfolioItem";

const portfolioData = [
  {
    id: 1,
    image: accenture,
    title: "carreira.accenture.titulo",
    start: "carreira.accenture.inicio",
    end: "carreira.accenture.fim",
    link: "https://www.accenture.com/br-pt/about/accenture-song-index",
    description: "carreira.accenture.descricao",
  },
  {
    id: 2,
    image: borabike,
    title: "carreira.borabike.titulo",
    start: "carreira.borabike.inicio",
    end: "carreira.borabike.fim",
    link: "https://www.instagram.com/borabike/",
    description: "carreira.borabike.descricao",
  },
  {
    id: 3,
    image: ufscar,
    title: "carreira.ufscar.titulo",
    start: "carreira.ufscar.inicio",
    end: "carreira.ufscar.fim",
    link: "https://www.editoraunifesp.com.br/produto/avaliacao+nos+processos+educacionais.aspx",
    description: "carreira.ufscar.descricao",
  },
];

const Portfolio = () => {
  return (
    <section className="pb-10">
      <div className="flex flex-wrap md:px-4">
        {portfolioData.map((portfolio, id) => (
          <PortfolioItem portfolio={portfolio} key={id} />
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
