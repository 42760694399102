import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from 'react-i18next';

const Contact = ({ t }) => {
  const [state, setState] = React.useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  }

  function handleSubmit(evt) {
    const formData = {
      ...state,
      'form-name': 'contact'
    };

    const formBody = Object.keys(formData).map(key => 
      encodeURIComponent(key) + '=' + encodeURIComponent(formData[key])
    ).join('&');

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formBody
    }

    fetch('/', options)
      .then(response => {
        toast.success(t('contato.sucesso'), {
          position: toast.POSITION.TOP_RIGHT,
          theme: "light"
        });

        setState({
          name: '',
          email: '',
          subject: '',
          message: ''
        });
      })
      .catch(error => {
        toast.error(t('contato.erro'), {
          position: toast.POSITION.TOP_RIGHT,
          theme: "light"
        });
      });

    evt.preventDefault();
  }

  return (
    <section className="pb-10">
      <div className="flex flex-wrap md:px-4">
        <form className="p-8 md:mx-4 bg-white rounded-md shadow-md" onSubmit={handleSubmit}>
          <div className="m-3">
            <h3 className="text-2xl text-gray-800 font-bold mb-2">
              {t('contato.vamosConversar')}
            </h3>
            <p className="text-sm text-gray-500 mb-2">{t('contato.subtitulo')}</p>
          </div>
          <div className="w-full flex flex-wrap">
            <div className="w-full md:w-1/2">
              <div className="m-3">
                <input
                  type="text"
                  name="name"
                  placeholder={t('contato.nome')}
                  required
                  className="w-full border border-gray-100 rounded py-4 px-6 text-sm bg-white"
                  value={state.name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div className="m-3">
                <input
                  type="email"
                  name="email"
                  required
                  placeholder={t('contato.email')}
                  className="w-full border border-gray-100 rounded py-4 px-6 text-sm bg-white"
                  value={state.email}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="w-full">
              <div className="m-3">
                <input
                  type="text"
                  name="subject"
                  required
                  placeholder={t('contato.assunto')}
                  className="w-full border border-gray-100 rounded py-4 px-6 text-sm bg-white"
                  value={state.subject}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="w-full">
              <div className="m-3">
                <textarea
                  name="message"
                  required
                  placeholder={t('contato.mensagem')}
                  rows="6"
                  className="w-full border border-gray-100 rounded py-4 px-6 text-sm bg-white"
                  value={state.message}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="w-full">
              <div className="m-3 text-right">
                <input
                  type="submit"
                  value={t('contato.enviar')}
                  className="rounded bg-purple-600 text-center border-0 py-3 px-6 text-white leading-7 tracking-wide hover:bg-purple-800 cursor-pointer"
                />
              </div>
            </div>
          </div>
          <ToastContainer />
        </form>
      </div>
    </section>
  );
};

export default withTranslation()(Contact);
