import React from "react";

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="bg-white p-4 absolute bottom-0 left-0 w-full">
      <div className="container px-4">
        <p className="text-sm text-gray-400 text-center">
          Copyright © { currentYear }
          <a
            className="text-purple-600"
            href="https://registry.jsonresume.org/danielsitnik?theme=kwan"
            rel="nofollow"
          >
            {" "}
            Daniel Sitnik
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
