import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    pt: {
        translation: {
            menu: {
                "sobre": "Sobre",
                "areas": "Áreas de Atuação",
                "carreira": "Carreira",
                "interesses": "Interesses",
                "contato": "Contato",
            },
            sidebar: {
                "na": "na",
                "cvDigital": "CV Digital",
                "sobreMim": "SOBRE MIM",
                "sobreMimTextoP1": "Olá! Sou um profissional com mais de 10 anos de experiência em consultoria, arquitetura, cloud, desenvolvimento e gestão de projetos de software.",
                "sobreMimTextoP2": "Atualmente lidero a prática de <1>Cloud Native Applications </1>da Accenture."
            },
            sobre: {
                titulo: "Quem sou eu?",
                textoP1: "Meu nome é Daniel, tenho {{age}} anos e moro em São Paulo, Brasil.",
                textoP2: "Atualmente sou o líder da prática de <1>Cloud Native Applications </1>da Accenture Song. Sou responsável por um grupo de cerca de 80 pessoas, por nossas ofertas de serviços, propostas, contratação, treinamento, engajamento e alocação dos times nos projetos.",
                textoP3: "Me formei em Administração de Empresas na Universidade Estadual de Londrina (<1>UEL</1>), e logo depois em Ciência da Computação na Universidade Federal de São Carlos (<3>UFSCar</3>). Também tenho um MBA Executivo pelo <5>Insper</5>.",
                textoP4: "Aprendi a programar sozinho com 14 anos e criei um software chamado <1><0></0><1>2002</1><2></2><3></3></1>, que fez bastante sucesso no Brasil, sendo citado em jornais e até distribuído em revistas de informática.",
                textoP5: "Fiz minha carreira na <1>Accenture</1>, onde comecei em 2010 como estagiário. Passei por diversos projetos, principalmente em clientes da Indústria Financeira, onde desenvolvi skills de arquitetura, consultoria e gestão de equipes de desenvolvimento.",
                skills: {
                    arquitetura: "Arquitetura",
                    gestao: "Gestão de Projetos",
                    cloud: "Amazon Web Services / Google Cloud",
                    quarkus: "Quarkus/GraalVM",
                    java: "Java",
                    api: "API Gateways (Kong, Apigee)",
                    javascript: "JavaScript / TypeScript"
                }
            },
            atuacao: {
                consultoria: {
                    titulo: "Consultoria",
                    descricao: "Consultoria e orientação para startups e negócios baseados em tecnologia, onde os empreendedores geralmente não são da área."
                },
                openfinance: {
                    titulo: "Open Finance",
                    descricao: "Trabalho com Open Banking desde 2017 e tenho grande experiência e interesse no assunto. Estou ansioso também pelo Open Insurance!"
                },
                gestao: {
                    titulo: "Gestão de Projetos",
                    descricao: "Planejamento e gestão de projetos e equipes de desenvolvimento de software, com utilização de metodologias ágeis."
                },
                arquitetura: {
                    titulo: "Arquitetura",
                    descricao: "Arquitetura de soluções end-to-end, com soluções Cloud Native modernas e aplicação de conceitos como Clean Architecture."
                },
                desenvolvimento: { 
                    titulo: "Desenvolvimento",
                    descricao: "Desenvolvimento de soluções com arquitetura de microsserviços e tecnologias Open Source como Java e JavaScript/TypeScript."
                },
                apis: {
                    titulo: "Open APIs",
                    descricao: "Modelagem e desenvolvimento de APIs, experiência em Open Banking e aplicação de padrões de segurança como OpenID Connect."
                }
            },
            carreira: {
                accenture: {
                    titulo: "Accenture Song",
                    inicio: "2010",
                    fim: "Hoje",
                    descricao: "Entrei na Accenture como estagiário e fiz minha carreira por aqui. Procuro fazer um mix de trabalhos de estratégia e tecnologia, sempre com clientes da indústria financeira."
                },
                borabike: {
                    titulo: "Bora Bike",
                    inicio: "2016",
                    fim: "2017",
                    descricao: "Tive uma rápida experiência como CTO de uma startup. O Bora Bike era um programa de incentivos para quem utilizava a bicicleta como meio de transporte."
                },
                ufscar: {
                    titulo: "UAB UFSCar",
                    inicio: "2007",
                    fim: "2009",
                    descricao: "Desenvolvi o sistema de gestão de notas e acompanhamento dos alunos da EaD UFSCar. Escrevemos até um capítulo de livro sobre o sistema!"
                }
            },
            interesses: {
                musica: {
                    titulo: "Música",
                    descricao: "Meus estilos preferidos são heavy metal e hard rock. Tentei aprender guitarra, mas sem sucesso. :)"
                },
                fotografia: {
                    titulo: "Fotografia",
                    descricao: "Sei o básico para usar o modo manual! Gosto muito de tirar fotos e fazer ajustes no Lightroom."
                },
                videogames: {
                    titulo: "Video Games",
                    descricao: "Gosto de me distrair com video games e os considero uma forma de arte. Minha plataforma atual é o PS5."
                },
                ciclismo: {
                    titulo: "Ciclismo",
                    descricao: "Meu esporte favorito é o ciclismo. Costumo andar nos fins de semana pelas ciclofaixas de SP."
                }
            },
            contato: {
                "vamosConversar": "Vamos Conversar! 🙂",
                "subtitulo": "Consultoria para startups, oportunidades de carreira, troca de experiências.",
                "nome": "Seu Nome",
                "email": "Seu Email",
                "assunto": "Assunto",
                "mensagem": "Sua Mensagem",
                "enviar": "Enviar Mensagem",
                "sucesso": "Mensagem enviada!",
                "erro": "Erro, por favor tente novamente."
            }
        }
    },

    en: {
        translation: {
            menu: {
                "sobre": "About",
                "areas": "Expertise",
                "carreira": "Career",
                "interesses": "Interests",
                "contato": "Contact",
            },
            sidebar: {
                "na": "at",
                "cvDigital": "Digital Resume",
                "sobreMim": "ABOUT ME",
                "sobreMimTextoP1": "Hello! I'm a professional with 10+ years of experience in consulting, architecture, cloud, development and software project management.",
                "sobreMimTextoP2": "I currently lead Accenture's <1>Cloud Native Applications </1>practice."
            },
            sobre: {
                titulo: "Who am I?",
                textoP1: "My name is Daniel, I am {{age}} years old and I live in São Paulo, Brazil.",
                textoP2: "I currently lead Accenture's <1>Cloud Native Applications</1> practice. I'm responsible for a group of almost 80 people, and for our service offerings, proposals, recruting, training, engagement and staffing of project teams.",
                textoP3: "I graduated in Business Administration on State University of Londrina (<1>UEL</1>), and soon after in Computer Science on Federal University of São Carlos (<3>UFSCar</3>). I have also attained an Executive MBA from <5>Insper</5>.",
                textoP4: "I self learned computer programming when I was 14 and I developed a software called <1><0></0><1>2002</1><2></2><3></3></1> which made a lot of success in Brazil, being cited in newspapers and distributed in computer magazines.",
                textoP5: "I made my career on <1>Accenture</1>, where I started in 2010 as an intern. I've been through several engagements, mainly with Financial Services clients, where I developed my skills around software architecture, consulting and management of development teams.",
                skills: {
                    arquitetura: "Solution Architecture",
                    gestao: "Project Management",
                    cloud: "Amazon Web Services / Google Cloud",
                    quarkus: "Quarkus/GraalVM",
                    java: "Java",
                    api: "API Gateways (Kong, Apigee)",
                    javascript: "JavaScript / TypeScript"
                }
            },
            atuacao: {
                consultoria: {
                    titulo: "Consulting",
                    descricao: "Consulting for startups and new technology based businesses, where enterpreneurs usually don't have a tech background."
                },
                openfinance: {
                    titulo: "Open Finance",
                    descricao: "I've been working with Open Banking since 2017 and I have a large experience on the subject. I'm also anxious for Open Insurance!"
                },
                gestao: {
                    titulo: "Project Management",
                    descricao: "Planning, management and support of software development teams, using agile methodologies."
                },
                arquitetura: {
                    titulo: "Solution Architecture",
                    descricao: "End-to-end solution architecture and design, with modern Cloud Native tech stack and concepts like Clean Architecture."
                },
                desenvolvimento: { 
                    titulo: "Software Development",
                    descricao: "Software development with microservices architecture and Open Source technologies like Java and JavaScript/TypeScript"
                },
                apis: {
                    titulo: "Open APIs",
                    descricao: "Modelling and development of APIs, Open Banking experience and usage of security patterns like OpenID Connect."
                }
            },
            carreira: {
                accenture: {
                    titulo: "Accenture Song",
                    inicio: "2010",
                    fim: "Present",
                    descricao: "I joined Accenture as an intern and made my career here. I usually do a mix of strategy and technology consulting, with a focus on financial services clients."
                },
                borabike: {
                    titulo: "Bora Bike",
                    inicio: "2016",
                    fim: "2017",
                    descricao: "I had a brief experience as the CTO of a startup. Bora Bike was like a loyalty program, rewarding people who used their bikes as a means of transportation."
                },
                ufscar: {
                    titulo: "UAB UFSCar",
                    inicio: "2007",
                    fim: "2009",
                    descricao: "I developed the grades and student progress systems for UFSCar's distance learning courses. We even wrote a book chapter about it!"
                }
            },
            interesses: {
                musica: {
                    titulo: "Music",
                    descricao: "My favorite genres are heavy metal and hard rock. I tried to learn to play guitar, but had no success. :)"
                },
                fotografia: {
                    titulo: "Photography",
                    descricao: "I know enough to shoot on manual mode! I like taking pictures and adjusting them on Lightroom."
                },
                videogames: {
                    titulo: "Video Games",
                    descricao: "I like to distract myself with video games and consider them a form of art. I currently play on PS5."
                },
                ciclismo: {
                    titulo: "Cycling",
                    descricao: "Cycling is my favorite sport. I usually ride on São Paulo's cycle lanes on the weekends."
                }
            },
            contato: {
                "vamosConversar": "Say Hello! 🙂",
                "subtitulo": "Startup consulting, career opportunities, knowledge exchange.",
                "nome": "Your Name",
                "email": "Your Email",
                "assunto": "Subject",
                "mensagem": "Your Message",
                "enviar": "Send Message",
                "sucesso": "Message sent!",
                "erro": "Error, please try again."
            }
        }
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;